.backgroundSI{
  padding-bottom: 150px;
  z-index: 50;
  background: linear-gradient(#fff,#fff);
}
.sigCanvas {
  border: 1px solid #212121;
}
.btn-link {
  font-weight: 400;
  color: #2b73e5;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
}
.growth:before{
  pointer-events: none;
  content: ' ';
  display: block;
  position: absolute;
  top: 65%;
  left: 50%;
  height: 40%;
  width: 30%;
  transform: translate(-50%, -50%);
  opacity: .1;
  background-image: url('../img/mundito.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: contain;
 }

 .growthib:before{
  pointer-events: none;
  content: ' ';
  display: block;
  position: absolute;
  top: 145%;
  left: 50%;
  height: 40%;
  width: 30%;
  transform: translate(-50%, -50%);
  opacity: .1;
  background-image: url('../img/mundito.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: contain;
 }
 
.contractBoldSub{
  font-weight: bold;
  text-decoration-line: underline;
}
.contractTitles{
  font-weight: bold;
  font-size:x-large ;
  color: #2b73e5;
  text-align: center;
}
.contractsubTitles{
  font-weight: bold;
  font-size:medium;
  color: #2b73e5;
  text-align: left;
}
.contractClausules{
  font-size:small;
  padding-left: 5%;
}
.contractsubClausules{
  font-size:small;
  padding-left:10%;
}
.contractsubSubClausules{
  font-size:small;
  padding-left:15%;
}
.contractsubsubSubClausules{
  font-size:small;
  padding-left:20%;
}
.contractAlign{
  text-align: center;
  font-size:small;
}
.contractBold{
  font-weight: bold;
  text-align: justify;
 }
.titleDiv{
  padding-top: 30px;
}
.login-logo-gnt{
  width: 20%;
}
.contract-background{
  position: absolute;
  top: 45%;
  left: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  opacity: .1;
 }

 .ibcontract-background{
  position: absolute;
  top: 120%;
  left: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  opacity: .1;
 }
 

.login-contenedor-columna-2 {
    margin-top: 15px;
    margin-right: 250px;
    margin-left: 250px;
    padding: 15px 35px;
    border: 1px solid #303B5A;
    background-color: #101820;
}
.login-titulo-center-columna-2 {
  color: #E2EEFF;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
}
.backgroundSI .row{
  margin:0;
  text-align: center;
}
.centerDiv{
  margin-bottom: 25px;
}
.signUpTxt{
  text-align: left;
  color:'black';
  
  font-weight: 200;
  padding:15px 80px;
}


.agreeText{
  text-align: left;
  color: #fff;
  margin-bottom:  2px;
  font-weight: 200;
  margin-left: 15px;
}
.scrollDiv{
  overflow-y: scroll;
  height: 55vh;
}
.growth {
  background-color: #fff;
 }
 .growthib {
  background-color: #fff;
 }
.signup-boton-signup, .signup-boton-signup.focus, .signup-boton-signup:hover {
  width: 60%;
  color: #fff !important;
  background-color: #35e0a5 !important;
  height: 50px;
  border:none !important;
}

.react-tel-input{
  margin:0 !important;
}
.react-tel-input .selected-flag .arrow{
  border-top: 4px solid  #FFFFFF !important;
}
.react-tel-input input[type=tel] {
    background-color: #0a1a3e!important;
    height: 50px!important;
}
.react-tel-input .selected-flag {
  height: 50px!important;
  z-index: 13!important;
  position: relative!important;
  width: 60px!important;
  padding: 0 0 0 18px!important;
  border-radius: 3px 0 0 3px!important;
  border:none !important;
  background-color: hsla(0,0%,47%,.2) !important;
}
.react-tel-input .flag-dropdown {
    position: absolute!important;
    top: 0!important;
    bottom: 0!important;
    padding: 0!important;
    background-color: hsla(0,0%,47%,.2) !important;
    border: none !important;
    border-radius: 3px 0 0 3px!important;
    outline: 0 solid transparent!important;
}
.react-tel-input input[type='text'], .react-tel-input input[type='tel']{
  box-shadow: none !important;
}
.react-tel-input{
width: 100% !important;
}
.react-tel-input input[type=tel], .react-tel-input input[type=text] {
  position: relative!important;
  z-index: 0!important;
  margin-top: 0!important;
  margin-bottom: 0!important;
  padding-left: 70px!important;
  margin-left: 0!important;
  border:none !important;
  width: 100%!important;
}

.phoneInput input{
  border: none !important;
}
.phoneInputErr input{
  border: 1px solid red !important;
}
.styleDatePicker{
  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #FFFFFF;
  border-radius: 4px;
  background-color: #0a1a3e!important;
  cursor: pointer;
  border: none;
}
.styleDatePickerError{
  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #FFFFFF;
  border-radius: 4px;
  background-color: #0a1a3e!important;
  cursor: pointer;
  border: 1px solid red;
}
.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
  padding: 0;
  border: 0;
}

.personalInfoRow .form-check-inline {
  width: 50%;
  color:#FFF;
  margin:0;
}

.signUpVar{
  color: #FFF;
  margin: 15px 0 0 0;
}
.signUpDropDown  .dropdown-toggle{
  background-color: transparent!important;
  color: #FFF!important;
  background-color: #0A1A3E!important;
  min-width: 250px;
  border:none !important;
}

.signUpDropDown  .dropdown-toggle:disabled {
  border:none !important;
}
.signUpDropDown .dropdown-toggle div, .signUpIdDrop .dropdown-toggle div{
  margin-left: 10px;
}
.signUpDropDownErr  .dropdown-toggle{
  background-color: transparent!important;
  color: #FFF!important;
  background-color: #0A1A3E!important;
  border:1px solid red;
}
.signUpDropDown  .dropdown-toggle:hover, .signUpDropDown  .dropdown-toggle:focus{
  background-color: transparent !important;
  color: #0A1A3E !important;
  background-color: #fff !important;
  border:none;
}

button:focus{
  outline: 0px !important;
}

.liveFileInput{
  border-radius: 5px;
  align-self: center;
  height: 150px;
  margin-top: 5px;
  width: 100%;
  color: #FFF;
  background-color: #0A1A3E;
  overflow: hidden;
  text-overflow: ellipsis;
  border:none;
}
.liveFileInput:nth-child(1){
  margin-left: 5px;
}

.errorFileInput{
  border-radius: 5px;
  align-self: center;
  height: 150px;
  margin-top: 2px;
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
  color: #FF0000;
  background-color: #0A1A3E;
  border: 1px solid red;
  overflow: hidden;
}
.signUpIdDrop{
  width:100%;
}
 .signUpIdDrop button, .signUpIdDrop .dropdown{
   background-color: transparent!important;
   color: #FFF!important;
   background-color: #0A1A3E!important;
   border: none;
   width:100%;
 }
 .formBody{
   color: #FFFFFF;
   text-align: center;
   font-size: 14px;
 }
 .formBottomBody{
   color: #FFFFFF;
   text-align: center;
   font-size: 14px;
   margin:0
 }
 .buttonRegisterContinue{
   width: 70%;
   font-family: kraftMono;
   color: #fff;
   font-size: 15px;
   background-color: #FAE100;
   border-color:#FAE100;
   margin: 0 50px;
   height: 50px;
 }

 .buttonRegisterContinueDisabled{
  width: 70%;
  font-family: kraftMono;
  color: #fff;
  font-size: 15px;
  background-color: #bcc0c5;
  margin: 0 50px;
  height: 50px;
}



 /* newwClient area  */
 .newClientContainer{
   margin-bottom: 30px;
   margin-top: 15px;
   padding: 15px 35px;
 }
 .Light .newClientContainer{
   background-color: #FDFEFF;
 }
 .Dark .newClientContainer{
   background-color: #131722;
 }
 .newClientContainer p {
   color:#9CA5B2;
   margin:0;
 }
 .newClientContainer .alert{
   border-radius: 0 !important;
   margin: 10px;
   padding: 10px;
 }
 .newClientContainer .alert .h4 {
    font-size: 14px;
    margin: 0;
  }
 .newClientContainer .alert-warning p {
    color: #85640A !important;
    font-size: 14px;
  }
 .newClientTitle{
   color: #132B51;
   font-size: 35px;
   font-weight: bold;
   text-align: center;
   margin-top: 25px;
   margin-bottom: 25px;
 }
 .newClientContainer .dropdown-toggle{
   height:38px !important;
 }
 .newClientContainer .react-tel-input .selected-flag {
   height: 38px !important;
 }

 .personalInfoRow{
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   margin-bottom: 15px !important;
 }
 .personalInfoRow:last-child{
   display: block;
 }
 .Light .newClientContainer .form-control,.Light .newClientContainer .form-control:focus,
 .Light .newClientContainer .form-control:active,.Light .styleDatePicker, .Light .newSubIBCommission{
   height: 38px !important;
   text-align: left;
   background-color: #FCFDFF !important;
   color:#4C566C !important;
   border: 1px solid #DBDFEA !important;
   border-radius: 0px;
 }
 .Light .newClientContainer .dropdown-toggle:focus,
 .Light .newClientContainer .dropdown-toggle:active{
   background-color: #FCFDFF !important;
   color:#4C566C !important;
   border: 1px solid #DBDFEA !important;
 }
 .Dark .newClientContainer .form-control,.Dark .newClientContainer .form-control:focus,
 .Dark .newClientContainer .form-control:active,.Dark .styleDatePicker, .Dark .newSubIBCommission{
   height: 38px !important;
   text-align: left;
   background-color: #192233 !important;
   color: #FFF !important;
   border: 1px solid #3a526e!important;
   border-radius: 0px;
 }
 .Dark .newClientContainer .dropdown-toggle:focus,
 .Dark .newClientContainer .dropdown-toggle:active{
   background-color: #192233 !important;
   color: #FFF !important;
   border: 1px solid #3a526e!important;
 }

 .Dark .newClientContainer .dropdown-menu{
   width:auto !important;
 }
 .Light .liveFileInput{
   background-color: #FCFDFF !important;
   color:#4C566C !important;
   border: 1px solid #DBDFEA !important;
 }
 .Dark .liveFileInput{
   background-color: #192233 !important;
   color: #FFF !important;
   border: 1px solid #3a526e!important;
 }

 .Light .errorFileInput{
   background-color: #FCFDFF !important;
 }
 .Dark .errorFileInput{
   background-color: #192233 !important;
 }


 @media (max-width:1700px) {
  .newClientContainer, .login-contenedor-columna-2{
    padding: 5px 15px;
  }
  .login-contenedor-columna-2 {
   margin-top: 15px;
   margin-right: 10px;
   margin-left: 10px;
   padding: 15px 35px;
  box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 50%);
   background-color: #101820;
 } 
 .growth:before{
  pointer-events: none;
  content: ' ';
  display: block;
  position: absolute;
  top: 65%;
  left: 50%;
  height: 50%;
  width: 35%;
  transform: translate(-50%, -50%);
  opacity: .1;
  background-image: url('../img/mundito.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: contain;
 }

 .growthib:before{
  pointer-events: none;
  content: ' ';
  display: block;
  position: absolute;
  top: 140%;
  left: 50%;
  height: 50%;
  width: 35%;
  transform: translate(-50%, -50%);
  opacity: .1;
  background-image: url('../img/mundito.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: contain;
 }
}

@media (max-width:850px) {
  .newClientContainer, .login-contenedor-columna-2{
    padding: 5px 15px;
  }
  .login-contenedor-columna-2 {
   margin-top: 15px;
   margin-right: 10px;
   margin-left: 10px;
   padding: 15px 35px;
  box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 50%);
   background-color: #101820;
 } 
 .growth:before{
  pointer-events: none;
  content: ' ';
  display: block;
  position: absolute;
  top: 65%;
  left: 50%;
  height: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  opacity: .1;
  background-image: url('../img/mundito.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: contain;
 }

 .growthib:before{
  pointer-events: none;
  content: ' ';
  display: block;
  position: absolute;
  top: 140%;
  left: 50%;
  height: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  opacity: .1;
  background-image: url('../img/mundito.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: contain;
 }
}

 @media (max-width:770px) {
.newClientContainer, .login-contenedor-columna-2{
     padding: 5px 15px;
   }
   .login-contenedor-columna-2 {
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 15px 35px;
  box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 50%);
    background-color: #101820;
    }
    .growth:before{
    pointer-events: none;
    content: ' ';
    display: block;
    position: absolute;
    top: 65%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    opacity: .1;
    background-image: url('../img/mundito.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
   }

   .growthib:before{
    pointer-events: none;
    content: ' ';
    display: block;
    position: absolute;
    top: 140%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    opacity: .1;
    background-image: url('../img/mundito.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
   }
 }


 @media (max-width:500px) {
   .newClientContainer, .login-contenedor-columna-2{
     padding: 5px 15px;
   }
   .login-contenedor-columna-2 {
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 15px 35px;
  box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 50%);
    background-color: #101820;
}
   .signUpTxt {
     font-size: 14px;
   }
   .signUpDropDown{
     padding: 0;
   }
   .newClientContainer .dropdown-toggle{
     font-size: 14px;
     padding: 3px 6px;
   }
   .formBody{
     text-align: left;
   }   

   .growth:before{
    pointer-events: none;
    content: ' ';
    display: block;
    position: absolute;
    top: 70%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    opacity: .1;
    background-image: url('../img/mundito.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
   }

   .growthib:before{
    pointer-events: none;
    content: ' ';
    display: block;
    position: absolute;
    top: 150%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    opacity: .1;
    background-image: url('../img/mundito.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
   }
 }
