@import url('https://fonts.googleapis.com/css?family=Lato|Source+Sans+Pro&display=swap');
@font-face {
  font-family: kraftMono;
  src:url(fonts/KraftMono.otf);
}

body {
  margin: 0;
  font-family: kraftMono;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0A1734;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)!important;
  background-color: #555!important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width:767px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

code {
}

img {
  vertical-align: middle;
}

.toast {
  z-index: 9999;
}

/* --- estilos sidenav --- */

.estilo-general-sidenav {
  margin-left: 64px;
  padding: 0px 5px 0px 0px;
  width: calc(100vw - 64px);
  position: absolute;
}

.sidenav-contenedor-datos-admin {
  margin-bottom: 50px;
  text-align: center;
}

.sideNav {
  position: fixed !important;
  top: 5px !important;
  left: 5px !important;
  bottom: 5px !important;
  padding: 10px 0;
  border-radius: 10px;
  justify-content: space-between;
  flex-direction: column;
}

.sideNavDark {
  background-color: #0E1A2E !important;
}

.sideNavLight {
  background-color: #185BC3 !important;
}

.sideNavText {
  margin: 0;
  width: 100%;
  line-height: 10px!important;
  position: absolute;
  font-size: 11px;
  bottom: 8px;
}

.sideNavBottomButton {
  display: block;
  position: relative;
  cursor: pointer;
  width: 64px;
  height: 50px;
  vertical-align: top;
  line-height: 40px;
  text-align: center;
}

.sideNavLogoHighlight {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  z-index: -1;
}

.sideNavBottomButton:hover .sideNavLogoHighlight {
  background: #fff;
  opacity: .15
}

.moreNavDrop {
  position: absolute;
  top: 0;
  left: 64px;
  width: 100;
}

.sideNavDark .moreNavDrop {
  background-color: #0E1A2E !important;
}

.sideNavLight .moreNavDrop {
  background-color: #185BC3 !important;
}

.sidenav---sidenav-nav---3tvij {}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  background-color: #2474BB !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  line-height: 40px !important;
  opacity: 1!important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo::after {
  position: relative;
  opacity: 1 !important;
  width: 100% !important;
}

/* .sidenav---sidenav-nav---3tvij .sideNavIcon {
  width: 30px !important;
} */

.sidenav---selected---1EK3y .sidenav---navtext---1AE_f {
  color: #4A95FF !important;
}

.sidenav---navtext---1AE_f {
  color: #B5B5B5 !important;
  font-size: 20px;
  margin-top: -15px;
}

/* .sidenav---navtext---1AE_f:active {
  color: #4A95FF !important;
} */

.sideNavItem {
  position: relative;
  margin-bottom: 10px;
}

.sidenav---expanded---1KdUL .sidenav---selected---1EK3y {
  background-color: #3F4458 !important;
  width: 150px;
  margin-left: 45px;
  border-radius: 5px;
}

.sidenav---collapsed---LQDEv .sidenav---selected---1EK3y {
  background-color: #3F4458 !important;
}

.sidenav---sidenav---_2tBP .sidenav---expanded---1KdUL .sidenav---navitem---9uL5T {
  display: inline-grid !important;
  width: 150px !important;
  height: auto !important;
  justify-content: center;
  border-radius: 20px !important;
}

.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ- {
  justify-content: center !important;
  display: flex !important;
}

.sidenav---collapsed---LQDEv .sidenav-contenedor-datos-admin {
  display: none;
}

.sidenav-nombre-administrador {
  color: #DEDEDE;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
}

.sidenav-rol-administrador {
  color: #DEDEDE;
  font-size: 15px;
  font-weight: 100;
}

.settingInfoText {
  font-size: 15px!important;
}

.homeModalLight .settingInfoText {
  color: #132B51
}

.homeModalDark .settingInfoText {
  color: #BCC4D8;
}
/* ---------------------- BANNER LIVE ---------------------- */
.bottomBannerContainer{
  padding-top: 20px!important;
  position: fixed!important;
  margin-left: auto!important;
  margin-right: auto!important;
  left: 0!important;
  right: 0!important;
  bottom: 0!important;
  width: 768px!important;
  height: 90px!important;
  z-index: 1000;
}
.bottomBannerContainer .carousel-indicators{
  display: none;
}
.bottomBannerContainer .carousel-control-next, .bottomBannerContainer .carousel-control-prev{

}
.bottomBanner {
  z-index: 1000!important;
  display: flex!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  width: 768px!important;
  height: 90px!important;
  padding-top: 20px!important;
  position: fixed!important;
  margin-left: auto!important;
  margin-right: auto!important;
  left: 0!important;
  right: 0!important;
  bottom: 0!important;
  cursor: pointer!important;
  transition: transform .2s;
}

.bottomBanner span {
  font-size: 17px;
  border-radius: 20px;
  background-color: #249AFF;
  color: #FFFFFF;
  padding: 7px 21px;
  border: 1px solid #FFF;
  margin: 12px 10px 0 -20px;
}

.bottomBanner h4 {
  margin: 0;
  color: #FFF;
}

.bottomBanner p {
  margin: 0;
  color: #FFF;
}

.bottomBanner p {
  margin: 0;
  color: #FFF;
}

.bottomBanner button {
  border-radius: 5px;
  margin: 7px 15px;
  background-color: #0056D0;
  color: #FFF;
}
/* ---------------------- BANNER SEASON ---------------------- */

.bottom-banner-space{
  height: 90px;
}

.bottomBannerSeason {
  z-index: 1000!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  height: 90px;
  padding-top: 20px;
  position: fixed!important;
  margin-left: auto!important;
  margin-right: auto!important;
  bottom: 0!important;
  cursor: pointer!important;
  transition: transform .2s;
  text-align: left;
}
.bannerTimerContainer{
  text-align: center;
  margin-right: 6rem;
  margin-top: -1rem;
}
.bottomBannerSeason div{
  display: inline-block;
  vertical-align: top;
}
.bottomBannerSeason div:first-child{
  margin-right: 40px;
  margin-left: 20px;
}
.bottomBannerSeason span {
  font-size: 17px;
  border-radius: 20px;
  background-color: #249AFF;
  color: #FFFFFF;
  padding: 7px 21px;
  border: 1px solid #FFF;
  margin: 0 0 0 -20px;
}

.bottomBannerSeason h4 {
  margin: 0;
  color: #00dbdb;
  font-weight: 800;
}

.bottomBannerSeason p {
  margin: 0;
  color: #FFF;
  font-weight: 600;

}


.bottomBannerSeason button {
  border-radius: 5px;
  margin: 7px 15px;
  background-color: #0056D0;
  color: #FFF;
}
.carousel-control-next-icon, .carousel-control-prev-icon{
  display: none !important;
}
/* ---------------------- BANNER FIN ---------------------- */
.newsFiltersCheckbox{
  text-align: left;
  display: inline-block;
  width:182px;
  margin: 0 10px;
}
.homeModalDark .newsFiltersCheckbox{
  color:#BDC4D7
}
.newsFiltersCheckbox.topFilter{
  float: right;
  padding-right: 15px;
  text-align: right;
}
.newsFiltersCheckbox .form-check{

}
.newsFiltersCheckbox .form-check input{
}

.newsFiltersCheckbox .form-check label{
  width:164px;
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.setupTitle{
  text-align: left;
  font-size: 18px !important;
  margin: 0;
  padding-left: 15px;
}
.homeModalDark .setupTitle{
  color: #BDC4D7
}


.setUpLast{
  text-align: center;
  font-size: 18px !important;
  margin: 0;
  padding-left: 15px;
  margin-top: 10px;
}
.setUpLastSub{
  text-align: center;
  font-size: 16px !important;
  margin: 0;
  padding-left: 15px;
}

.homeModalDark .setUpLast{
  color: #8596AF
}
.homeModalDark .setUpSubLast{
  color: #BDC4D7
}
/* .homeModalLight .setupContainer{
  color: #BDC4D7
} */
.homeModal .setupContainer{
  padding: 10px 0 0 10px;
  margin:0;
  overflow-x: auto;
  margin: 10px 0;
  height: 250px;
}
.homeModalLight .setupContainer{
  border-top: 1px solid #F4F9FF;
  border-bottom: 1px solid #F4F9FF;
}
.homeModalDark .setupContainer{
  border-top: 1px solid #353D4E;
  border-bottom: 1px solid #353D4E;
}

.firstTimePriceCard{
  width:33.333%;
  display: inline-block;
  cursor: pointer;
}
.homeModalLight .firstTimePriceCard{

}
.homeModalDark .firstTimePriceCard{

}
.digits-google-autenticathor{
  padding:0 !important;
  text-align: center;
}
@media (max-width: 1510px) {
  .bottomBanner {
    width: 70%;
    height: 70px;
    padding-top: 8px;
  }
}

@media (min-width: 767px){
  .sideNavSettings{
    display: none;
  }
}
.sideNavIcon{
  max-height: 20px;
}

@media (max-width: 767px) {
  .intercom-namespace .intercom-ls7tm9, .intercom-namespace .intercom-1ofvmxd {
    bottom: 60px!important;
    right: 5px!important;
  }
  .toast {
    position: fixed !important;
  }
  .sideNav {
    position: fixed !important;
    top: auto!important;
    right: 5px!important;
    justify-content: center;
    padding: 0;
  }
  .sideNavLogo {
    display: none;
  }
  .sideNavItem {
    margin: 0 !important;
    flex: 1;
    display: inline-block !important;
  }
  .sideNavIcon {
    margin-top: -10px;
  }
  .sideNavText {
    bottom: 7px !important;
  }
  .sidenav---sidenav-toggle---1KRjR {
    display: none !important;
  }
  .sidenav---sidenav-nav---3tvij {
    justify-content: center;
    flex-direction: row;
    display: flex !important;
  }
  .sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
    margin: 0!important;
    width: 100%!important;
  }
  .wallet-background {
    width: 100vw !important;
    margin: 0px !important;
  }
  .bottomBanner {
    position: relative;
    width: 100%;
    height: fit-content;
    padding-top: 8px;
    padding-bottom: 5px
  }
  .bottomBanner h4 {
    font-size: 18px;
  }
  .bottomBanner p {
    font-size: 14px;
  }
  .bottomBanner button {
    display: none
  }
  .bottomBanner span {
    font-size: 14px;
    margin: 5px 10px;
  }
}

.container_alert_renew_sesion .toast-header {
  background-color: #0E1A2E;
  color: #FFFFFF;
}

.container_alert_renew_sesion .toast-header span {
  color: #FFFFFF;
}

.container_alert_renew_sesion {
  background-color: rgb(25, 34, 51) !important;
  color: #FFFFFF;
}

.container_alert_renew_sesion button {
  background-color: #249AFF;
  font-size: 13px;
}

.boton-goback-forgot{
  color: #C1D9FF !important;
  font-size: 17px !important;
  text-align: right !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.boton-goback-forgot:hover{
  text-decoration: underline !important;
}



/* TIMER JS */
/* ---------------------------- TIMER JS ---------------------------- */

.bottomBannerSeason .season-Timer-Contador{
  font-size: 25px;
  color: white;
}
.bottomBannerSeason .season-Timer-Text-d-h-m-s{
  font-size: 12px;
  /* color: #00DBDB; */
  color: #b6b7b8 ;
}
.bottomBannerSeason .Timer-Countdown-container{
  display: inline-block;
}
.bottomBannerSeason .Timer-Countdown-table{
  text-align: center;
  width:  100px;
  /* margin-left: 6em!important; */
}

.bottomBannerSeason .Timer-Countdown-table th,.Timer-Countdown-table td{
  padding: 0;
  margin: 0;
  text-align: center;
  /* margin-left: 6em!important; */
}


@media (max-width:767px) {
  .bottomBannerSeason{
    top: 0 !important;
    left: 0!important;
    right: 0!important;
    bottom: unset!important;
    padding: 5px;
    text-align: center;
    background: #1952AF;
  }
  .bottomBanner{
    top: 0 !important;
    left: 0!important;
    right: 0!important;
    bottom: unset!important;
    padding: 5px;
    text-align: center;
    width: 100vw !important;
  }
  .bottomBannerSeason div{
    display: block;
  }
  .bottomBannerSeason div:first-child{
    margin:0
  }
  .bottomBannerSeason h4{
    font-size: 20px;
  }
  .top-banner-space{
    height: 90px;
  }
  .bottom-banner-space{
    height: 0;
  }
  .intercom-lightweight-app-launcher{
    bottom:65px !important;
  }
  .bottomBannerSeason button{
    display: none;
  }
  .bottomBannerSeason{
    height: 100px;
  }
  .bannerStartsIn{
    font-size: 15px;
  }
  .bottomBannerSeason .season-Timer-Contador{
    font-size: 17px;
  }

  .bannerText{
    display:none
  }
  .bannerTimerContainer{
    margin:0;
  }
}
